<template>
    <el-container class="login_container" :style="bg ? '' : 'background: none;'">
        <div class="login-box">
            <!-- Logo区域 -->
            <img v-if="logo" class="avatar_box_logo" src="@/assets/images/logo.png" alt="">
            <el-tabs v-model="activeName" @tab-click="handleClick" class="login_form">
                <el-tab-pane label="密码登录" name="first">
                    <!-- 登录表单区域 -->
                    <el-form label-width="" ref="loginFormRef" :model="loginForm" :rules="loginFormRules">
                        <!-- 用户名 -->
                        <el-form-item prop="username">
                            <el-input v-model="loginForm.username" prefix-icon="el-icon-user" placeholder="用户名"></el-input>
                        </el-form-item>
                        <!-- 密码 -->
                        <el-form-item prop="password">
                            <el-input v-model="loginForm.password" prefix-icon="el-icon-lock" type="password" placeholder="密码" :show-password="true"></el-input>
                        </el-form-item>
                        <!-- 按钮 -->
                        <el-form-item class="btns">
                            <el-button type="text" @click="login">忘记密码</el-button>
                            <el-button class="login" type="primary" @click="login">登录</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <!--<el-tab-pane label="免密码登录" name="second">
                    <el-form label-width="" ref="loginMobileFormRef" :model="loginMobileForm" :rules="loginMobileFormRules">
                        &lt;!&ndash; 手机号 &ndash;&gt;
                        <el-form-item prop="tel">
                            <el-input v-model="loginMobileForm.tel" placeholder="手机号"></el-input>
                        </el-form-item>
                        &lt;!&ndash; 验证码 &ndash;&gt;
                        <el-form-item prop="code">
                            <el-input placeholder="请输入验证码" maxlength="6" v-model="loginMobileForm.code">
                                <template slot="append">
                                    <el-button type="button" class="sendcode" @click="sendcode" v-if="disabled === false">获取验证码</el-button>
                                    <el-button type="button" class="sendcode" :disabled="disabled" v-if="disabled === true">{{btntxt}}
                                    </el-button>
                                </template>
                            </el-input>
                        </el-form-item>
                        &lt;!&ndash; 按钮 &ndash;&gt;
                        <el-form-item class="btns">
                            <el-button type="text" @click="login">邮箱登录</el-button>
                            <el-button class="login" type="primary" @click="login">登录</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>-->
                <div class="socialLogin">
                    <el-row>
                        <el-col :span="24">未注册手机验证后自动登录，注册即代表同意《协议》《隐私保护指引》</el-col>
                    </el-row>
                    <!--<el-row>
                        <el-col :span="16" class="socialLogin-title">社交帐号登录</el-col>
                        <el-col :span="8">
                            <el-button type="text">
                                <i class="iconfont icon-weixin"></i>微信
                            </el-button>
                            <el-button type="text">
                                <i class="iconfont icon-QQ"></i>QQ
                            </el-button>
                        </el-col>
                    </el-row>-->
                </div>
            </el-tabs>
        </div>
    </el-container>
</template>

<script>
import { mapActions } from 'vuex'


export default {
    name: 'login',
    props: {
        bg: {
            type: Boolean,
            default: true
        },
        logo: {
            type: Boolean,
            default: true
        }
    },
    data() {
        // 验证手机号规则
        var checkMobile = (rule, value, callback) => {
            // 验证手机号正则
            const regMObile = /^1[3|4|5|6|7|8|9]\d{9}$/
            if(regMObile.test(value)){
                return callback()
            }
            callback(new Error('请输入正确的手机号'))
        }
        return {
            activeName: 'first',
            // 登录表单绑定的对象
            loginForm: {
                username: '',
                password: ''
            },
            // 表单验证规则
            loginFormRules: {
                // 验证用户名
                username: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' }
                ]
            },
            // 手机登录
            loginMobileForm:{
                tel: '',
                code: ''
            },
            loginMobileFormRules: {
                tel: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { validator: checkMobile, trigger: 'blur' }
                ],
                // 验证码
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    { min: 6, max: 6, message: '验证码为6位数字', trigger: 'blur' }
                ]
            },
            disabled: false,
            time: 0,
            btntxt: "重新发送"
        }
    },
    created() {
        const apiAuth = window.sessionStorage.getItem('apiAuth')
        if(apiAuth) {
        //    this.$router.push('/'); 
        }
    },
    methods: {
        ...mapActions([
            'handleLogin'
        ]),
        // 登录验证
        login(){
            let username = this.loginForm.username
            let password = this.loginForm.password
            this.$refs.loginFormRef.validate(valid => {
                if (valid) {
                    this.handleLogin({ username, password }).then(() => {
                        this.$message.success('登录成功')
                        let goTo = this.$route.query.redirect
                        // console.log(goTo)
                        if( !goTo ) goTo = '/'
                        this.$router.push(goTo)
                    }).catch(() => {
                        return this.$message.error('登录失败');
                    })
                }
            })
        },
        handleClick() {

        },
        // 获取验证码
        async sendcode() {
            const reg = /^1[3|4|5|6|7|8|9]\d{9}$/
            if( this.loginMobileForm.tel === '' ) {
                this.$message.error('手机号不能为空')
                return
            }

            if( !reg.test(this.loginMobileForm.tel) ) {
                this.$message.error('请输入正确的手机号')
                return
            }
            
            const {data: res} = await this.$http.get('/Code/index')
            console.log(res)

            console.log(this.loginMobileForm.tel)
            this.$message.success('发送成功')
            this.time = 60
            this.disabled = true
            this.timer()
        },
        //60S倒计时
        timer() {
            if (this.time > 0) {
                this.time--
                this.btntxt = this.time + "s后重新获取"
                setTimeout(this.timer, 1000)
            } else {
                this.time = 0
                this.btntxt = "获取验证码"
                this.disabled = false
            }
        }
    }
}
</script>

<style lang="less" scoped>
.login_container{
    background-image: url('~@/assets/images/bg.png');
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-color: #b8e5f8;
    background-size: cover;
    width: 100%;
    height: 100vh;
    overflow: auto;
}

.login-box{
    -webkit-box-flex: 1;
    -ms-flex: 1 1;
    flex: 1 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 2px;
    min-height: 688px;
    height: calc(100% - 42px);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.login_form{
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    padding: 20px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 3px rgba(26,26,26,0.1);
    border-radius: 2px;
    background-color: #FFFFFF;
    width: 400px;
    overflow: hidden;
}
.avatar_box_logo{
    width: 128px;
    height: 80px;
    margin-bottom: 20px;
}
.btns .login{
    width: 100%;
    margin: 0;
}
.socialLogin .el-row{
    color: #8590a6;
    margin-top: 20px;
}
.socialLogin-title{
    font-size: 1rem;
    margin-top: 12px;
}
.socialButton{
    line-height: 30px;
}
.icon-weixin{
    color: #60c84d;
    margin-right: 6px;
}
.icon-QQ{
    color: #50c8fd;
    margin-right: 6px;
}
.sendcode{
    color: #0077e6 !important;
}
</style>